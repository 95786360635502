//import 'bootstrap/js/dist/alert';
//import 'bootstrap/js/dist/button';
//import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
//import 'bootstrap/js/dist/popover';
//import 'bootstrap/js/dist/scrollspy';
//import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/toast';
//import 'bootstrap/js/dist/tooltip';
//import 'bootstrap/js/dist/util';

import css from './sass/app.sass';




jQuery(function($) {

    $(document).ready(function() {
        if (window.location.href.indexOf("#rdv") > -1) {
            $('body').toggleClass('appointment-effect');
        }
      });

    $('.navbar-toggler-icon').on('click', function() {
        $('body').toggleClass('overflow-hidden');
    });

    var positionTop = $(window).scrollTop();
    var hideToTop;

    $(window).scroll(function() {
        clearTimeout(hideToTop);

        if( $(this).scrollTop() > 300 && $(this).scrollTop() < positionTop ) {
            $('#toTop').addClass('show');
            hideToTop = setTimeout(function() { 
                $('#toTop').removeClass('show');
            }, 5000);
        } else {
            $('#toTop').removeClass('show')
        }

        positionTop = $(this).scrollTop();
    });


    $("form[data-action]").on('submit', function(e) {
        e.preventDefault();
        var form = $(this);
        var formId = $(this).attr('id');

        if( form.hasClass('submit') ) {
            return false;
        }

        var formData = new FormData(this);
        formData.append('action', form.attr('data-action'));
        form.addClass('submit');
        $('.form-group', form).removeClass('error');
        $('.text-danger', form).remove();

        $.ajax({
            url: ajaxurl,
            type: 'POST',
            data: formData,
            cache: false,
            processData: false,
            contentType: false,
            dataType: 'json',
            success: function ( data ) {
                form.removeClass('submit');

                if(data.status == 200) {
                    form[0].reset();
                    var messageType = 'success';

                    $("#toasty_" + form.attr('data-action')).removeClass("alert-danger");
                    $("#toasty_" + form.attr('data-action')).addClass("alert-success");
                    $("#toasty_" + form.attr('data-action') + " .toast-body span.message").html(data.message);
                    $("#toasty_" + form.attr('data-action')).toast('show');

                } else {
                    var messageType = 'error';
                    $.each(data.errors, function(index, error){
                        var formGroup = $('#'+formId+' [name='+index+']').closest('.form-group, .form-check, .form-droppable');
                        console.log('#'+formId+' [name='+index+']');
                        formGroup.addClass('error');
                        formGroup.append('<div class="text-danger mt-1">' + error[0] + '</div>');
                    });
                    console.log(form.attr('data-action'))
                    $("#toasty_" + form.attr('data-action')).removeClass("alert-success");
                    $("#toasty_" + form.attr('data-action')).addClass("alert-danger");
                    $("#toasty_" + form.attr('data-action') + " .toast-body span.message").html(data.message);
                    $("#toasty_" + form.attr('data-action')).toast('show');
                }
            },
            error: function () {
                alert("Une erreur inconnue s'est produite.");
                form.removeClass('submit');
            }
        });
    });

});